/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./TimeVenue.css";

export default function TimeVenue({ data }) {
    const imgCDNURL = process.env.REACT_APP_CDN_SPL;

    return (
        <>
            <div className="time-venue d-flex">
                <Container>
                    <Row>
                        <Col sm={12} md={12} xl={12} lg={12} className="purple">
                            <div>
                                {HTMLReactParser(data.dateAndVenue.otherDetails)}
                            </div>
                        </Col>
                        {/* <Col sm={12} md={12} xl={5} lg={5} className="time-col">
                            <Row className="pb-1 clock-cal">
                                <Col sm={12} md={12} xl={5} lg={5}>
                                    <div>
                                        <img src={imgCDNURL + "calendar.png"} style={{ marginRight: "10px", marginBottom: "12px" }} />
                                        {HTMLReactParser(data.dateAndVenue.date)}
                                    </div>
                                </Col>
                                <Col sm={12} md={12} xl={5} lg={6}>
                                    <div className="time">
                                        <img src={imgCDNURL + "clock.png"} style={{ marginRight: "15px", marginBottom: "5px" }} />
                                        {HTMLReactParser(data.dateAndVenue.time)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-flex">
                                        <img src={imgCDNURL + "pin.png"} style={{ marginRight: "10px", height: "30px", width: "30px" }} />
                                        <div>{HTMLReactParser(data.dateAndVenue.venue)}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </>
    );
}
