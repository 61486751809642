/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./WhatYouLearn.css";

export default function WhatYouLearn({ data }) {
  const imgCDNURL = process.env.REACT_APP_CDN_SPL;

  return (
    <>
      <div>
        <Container className="learn-container mobile">
          <Row>
            <div>
            <Col>
              <div>
                <h4>{HTMLReactParser(data.whatYouWillLearn.heading)}</h4>
              </div>
              <div>{HTMLReactParser(data.whatYouWillLearn.points)}</div>
            </Col>
            </div>
          </Row>
        </Container>
        <Container className="learn-container desktop">
          <Row>
            <Col>
              <div>
                <h4>{HTMLReactParser(data.whatYouWillLearn.heading)}</h4>
              </div>
            </Col>
          </Row>
          <Row className="d-flex learn-row">
            <Col xl={6} lg={6}>
            <div>{HTMLReactParser(data.whatYouWillLearn.pointsMobile1)}</div>
            </Col>
            <Col xl={6} lg={6}>
            <div>{HTMLReactParser(data.whatYouWillLearn.pointsMobile2)}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
